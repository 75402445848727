import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import PageTemplate from '../templates/pageTemplate';
import PostLink from "../components/post-link"

const BlogIndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge => edge.node.frontmatter.path.includes("/blog/"))
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)
  
  return (
    <PageTemplate
      title="Learn more about the keto diet"
      description="Curious about the ketogenic diet? Our blog is the perfect place to learn more about how to lose weight and have more energy while eating delicious food."
    >
      <h1>Learn more about the keto diet.</h1>
      <p>
        Our blog is the perfect place to start if you're curious about the ketogenic diet and how it can benefit your health.
        With keto, you can meet your weight loss goals, improve your general health, and increase your energy, all while eating delicious, easy to make foods.
      </p>

      <PostsContainer>
        {Posts}
      </PostsContainer>
    </PageTemplate>
  ) 
}

export default BlogIndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            image {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

const PostsContainer =  styled.div`
  margin-top: 50px;

`
